<!-- System: STA
    Purpose: Edit user info display modal
            for edit user details and permissions.
-->
<template>
  <!-- Edit User Info Dialog -->
  <v-dialog v-model="user_info_modal" width="500">
    <v-card>
      <v-card-title class="headline white lighten-2 justify-center">
        Edit User Info
      </v-card-title>
      <v-divider></v-divider>
      <!-- Tabs For Account and System Information -->
      <v-row class="mt-3 mb-3" v-if="member.silent_user">
        <v-tabs class="d-flex justify-center" v-model="selectedTab">
          <v-tabs-slider color="blue"></v-tabs-slider>
          <v-tab
            key="user"
            @click="
              show_user_account_information = true;
              show_user_system_information = false;
            "
            class="font-size"
            >Account information</v-tab
          >
          <v-tab
            key="system"
            @click="
              show_user_system_information = true;
              show_user_account_information = false;
            "
            class="font-size"
            >System information</v-tab
          >
        </v-tabs>
      </v-row>
      <!-- Edit User Info Form -->
      <v-container>
        <v-form
          v-show="show_user_account_information"
          ref="form"
          @submit.prevent="submitEditProfile"
          novalidate="true"
        >
          <v-row>
            <!-- Name Text Field -->
            <v-col cols="12" md="10" offset-md="1">
              <v-text-field
                dense
                id="name-input"
                v-model="EditName"
                required
                :rules="userNameRules"
                outlined
                label="Enter Name"
              ></v-text-field>
            </v-col>
            <!-- Email Text Field -->
            <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-n6"
              v-if="getUserSetting.secondary_email == null"
            >
              <v-text-field
                id="email-input"
                dense
                required
                readonly
                :rules="emailRules"
                outlined
                v-model="member.email"
                label="Email Address"
              ></v-text-field>
            </v-col>
            <!-- Secondary Email Text Field -->
            <!-- <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-n6"
              v-if="member.silent_user"
            > -->
            <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-n6"
            >
              <v-text-field
                id="email-input-2"
                dense
                :rules="emailRules"
                outlined
                v-model="getUserSetting.secondary_email"
                label="Secondary Email Address"
              ></v-text-field>
            </v-col>
            <!-- Department List -->
            <v-col cols="12" md="10" offset-md="1" class="mt-n7">
              <!-- <div>
                <v-text-field
                  type="text"
                  label="Add or Select Department"
                  title="Department Name"
                  @click="show_department"
                  v-model="EditDept"
                  class="edit-margin edit-seprate2 custom-department add_select_dep"
                  readonly
                  dense
                  outlined
                ></v-text-field>
                <div class="department" v-if="check_department === true">
                  <input
                    type="text"
                    class="department-input"
                    id="department_add"
                    v-model="search_add_department"
                    name="deaprtment"
                    placeholder="Search And Add Department"
                  />
                  <img
                    class="image-icon ml-2 pt-2"
                    @click="add_department_new"
                    title="Type and click + Button to Add New Department"
                    src="@/assets/images/plus_department.svg"
                  />
                  <div class="department_dev"></div>
                  <ul style="list-style: none" class="scroll-department">
                    <li
                      class="list-department"
                      v-for="department in departments"
                      :key="department.id"
                      :class="
                        EditDept === department.name ? 'active_department' : ''
                      "
                      @click="select_department_list(department)"
                    >
                      {{ department.name }}
                    </li>
                  </ul>
                </div>
              </div> -->
              <v-combobox
                v-model="selected_department_id"
                :hide-no-data="!search"
                :items="departments"
                item-text="name"
                item-value="hash_id"
                :search-input.sync="search"
                label="Search And Add Department"
                small-chips
                :loading="departmentLoader"
                multiple
                outlined
                dense
              >
                <template v-slot:no-data>
                  <v-list-item @click="addDeparmentNew()">
                    <v-chip label small class="mr-4">
                      {{ search }}
                    </v-chip>

                    <span class="caption"> < Click to add new department></span>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <!-- Designation Text Field -->
            <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-n6"
              @click="check_department = false"
            >
              <v-text-field
                id="designation-input"
                required
                dense
                outlined
                v-model="EditDesg"
                label="Enter Designation"
              ></v-text-field>
            </v-col>
            <!-- Work Shift Dropdown -->
            <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-n6 d-none"
              @click="check_department = false"
              v-if="checkKey"
            >
              <v-autocomplete
                id="Shift-input"
                dense
                outlined
                :items="shifts"
                v-model="selectedShift"
                label="Select Shift"
              ></v-autocomplete>
            </v-col>
            <!-- Seetings -->
            <v-col cols="12" md="10" offset-md="1" class="mt-n4">
              <label class="my-Setting-label d-flex align-start"
                >Setting Actions:</label
              >
              <v-row>
                <!-- Admin  Permission Checkbox -->
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    member.admin !== 'ADMIN' &&
                    adminPermission &&
                    user.id !== member.id &&
                    !member.silent_user
                  "
                >
                  <v-checkbox
                    @click="make_admin('ADMIN')"
                    :disabled="disabled"
                    v-model="adminStatus"
                    dense
                    class="my-checkbox"
                    label="Admin"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    member.admin === 'ADMIN' &&
                    adminPermission &&
                    user.id !== member.id &&
                    !member.silent_user
                  "
                >
                  <v-checkbox
                    @click="remove_member_role('ADMIN')"
                    dense
                    class="my-checkbox"
                    label="Admin"
                    v-model="is_admin"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" class="d-none">
                  <!-- SUPERSECUREUSER  Permission Checkbox -->
                  <v-checkbox
                    v-if="member.super_secure_user !== 'SUPERSECUREUSER'"
                    @click="make_admin('SUPERSECUREUSER')"
                    dense
                    class="my-checkbox"
                    label="Super Secure User"
                    :disabled="disabled_super"
                  ></v-checkbox>
                  <v-checkbox
                    @click="remove_member_role('SUPERSECUREUSER')"
                    v-if="member.super_secure_user === 'SUPERSECUREUSER'"
                    dense
                    class="my-checkbox"
                    label="Super Secure User"
                    v-model="is_supersecure"
                    :disabled="disabled_super"
                  ></v-checkbox>
                </v-col>
                <!-- Capture Secreen  Permission Checkbox -->
                <v-col
                  cols="12"
                  md="6"
                  :class="
                    member.silent_user || user.id == member.id ? '' : $vuetify.breakpoint.smAndDown ? 'mt-n13' : ''
                  "
                >
                  <v-checkbox
                    dense
                    v-model="getUserSetting.capture_screen"
                    class="my-checkbox"
                    label="Capture Screen"
                  ></v-checkbox>
                </v-col>
                <!-- View Backoffice  Permission Checkbox -->
                <v-col
                  cols="12"
                  md="6"
                  class="mt-n13"
                  v-show="!member.silent_user"
                >
                  <v-checkbox
                    dense
                    v-model="getUserSetting.view_backoffice"
                    class="my-checkbox"
                    label="View Backoffice"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" class="mt-n13">
                  <!-- Blur Screenshot Permission Checkbox -->
                  <v-checkbox
                    dense
                    class="my-checkbox"
                    v-model="getUserSetting.blur_screenshots"
                    label="Blur Screenshots"
                  ></v-checkbox>
                </v-col>
                <!-- Manual Time Add Permission Checkbox -->
                <v-col cols="12" class="mt-n13" v-show="!member.silent_user">
                  <v-checkbox
                    dense
                    class="my-checkbox"
                    label="Manual Time Add"
                    v-model="getUserSetting.manual_time_allowed"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <!-- Screen Shot Time Interval -->
            <v-col cols="12" md="10" offset-md="1" class="mt-n4">
              <v-row>
                <v-col cols="6">
                  <label
                    class="d-flex align-start"
                    style="font-size: 12px; color: #36454f"
                    >Screen Shot Time Interval</label
                  >
                </v-col>
                <v-col cols="6" v-if="member.silent_user"> 
                  <label
                    class="d-flex align-start"
                    style="font-size: 12px; color: #36454f"
                    >Keystrokes And Mouse Delay</label
                  >
                </v-col>
              </v-row>
              <br />
              <v-row class="mt-n6">
                <v-col cols="1">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    :disabled="member.member_status !== 'active'"
                    @click="
                      update_member_interval(
                        parseInt(getUserSetting.interval) - 1
                      )
                    "
                    x-small
                    color="#2758f6"
                    ><v-icon dark>mdi-minus</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="3">
                  <p class="intervalTime pt-1 ml-4">
                    {{ pluralize(getUserSetting.interval, "min") }}
                  </p>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    fab
                    dark
                    :disabled="member.member_status !== 'active'"
                    @click="
                      update_member_interval(
                        parseInt(getUserSetting.interval) + 1
                      )
                    "
                    x-small
                    color="#2758f6"
                    class="ml-n4"
                    ><v-icon dark>mdi-plus</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="6" v-if="member.silent_user">
                  <v-text-field
                    class="d-flex ml-10"
                    label="Enter Minutes"
                    dense
                    small
                    v-model="EditKeystrokesDelay"
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- Admin Permission -->
            <v-col
              cols="12"
              md="10"
              offset-md="1"
              class="mt-n4"
              v-show="!member.silent_user"
              v-if="
                member.admin === 'ADMIN' &&
                adminPermission &&
                user.id !== member.id
              "
            >
              <label class="my-Setting-label d-flex align-start"
                >Admin Permissions:</label
              >
              <v-row>
                <!-- Access Dashboard Permission Checkbox -->
                <v-col cols="12" md="6">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.dashboard"
                    label="Access Dashboard"
                  ></v-checkbox>
                </v-col>
                <!-- Access Project Permission Checkbox -->
                <v-col cols="12" md="6">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.project"
                    label="Access Project"
                  ></v-checkbox>
                </v-col>
                <!-- Access Tasks Permission Checkbox -->
                <v-col cols="12" md="6" class="mt-n13">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.task"
                    label="Access Tasks"
                  ></v-checkbox>
                </v-col>
                <!-- Access Team Members Permission Checkbox -->
                <v-col cols="12" md="6" class="mt-n13">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.team_member"
                    label="Access Team Members"
                  ></v-checkbox>
                </v-col>
                <!-- Access Work Diary Permission Checkbox -->
                <v-col cols="12" md="6" class="mt-n13">
                  <v-checkbox
                    dense
                    class="my-checkbox"
                    v-model="getUserSetting.work_diary"
                    label="Access Work Diary"
                  ></v-checkbox>
                </v-col>
                <!-- Access Reports Permission Checkbox -->
                <v-col cols="12" md="6" class="mt-n13">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.reports"
                    label="Access Reports"
                  ></v-checkbox>
                </v-col>
                <!-- Access Billing Permission Checkbox -->
                <v-col cols="12" md="6" class="mt-n13">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.billing"
                    label="Access Billing"
                  ></v-checkbox>
                </v-col>
                <!-- Access Admin Permission Checkbox -->
                <v-col cols="12" md="6" class="mt-n13">
                  <v-checkbox
                    class="my-checkbox"
                    dense
                    v-model="getUserSetting.admin_permission"
                    label="Access Admin"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Update Profile Button -->
          <v-row class="d-flex justify-center">
            <v-btn
              type="submit"
              ref="submit"
              color="#2758f6"
              class="white--text mb-5"
              :disabled="updatingRole"
            >
              Update Profile
            </v-btn>
          </v-row>
        </v-form>
        <!-- System Information Tab Details -->
        <div class="my-4 mx-4" v-show="show_user_system_information">
          <label class="small text-secondary"> System Architecture: </label>
          <h5 style="line-height: 0.7 !important" class="mt-2 mb-3">
            {{ systemDetails.architecture }}
          </h5>
          <label class="small text-secondary"> System Platform:</label>
          <h5 style="line-height: 0.7 !important" class="mt-2 mb-3">
            {{ systemDetails.platform }}
          </h5>
          <label class="small text-secondary"> System Model:</label>
          <h5 style="line-height: 0.7 !important" class="mt-2 mb-3">
            {{ systemDetails.model }}
          </h5>
          <label class="small text-secondary"> Serial Number:</label>
          <h5 style="line-height: 0.7 !important" class="mt-2 mb-3">
            {{ systemDetails.serial_no ? systemDetails.serial_no : 'N/A' }}
          </h5>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { sha256 } from "js-sha256";
import { UserNameRules, emailRules, DesignationRules } from "@/Constants/Const";
import { mapState } from "vuex";
export default {
  name: "EditUserInfo",
  data() {
    return {
      departmentLoader: false,
      search: null,
      is_admin: true,
      updatingRole: false,
      is_supersecure: true,
      member: "",
      menu: false,
      selectedTab: "user",
      adminStatus: false,
      user_info_modal: false,
      show_user_account_information: true,
      show_user_system_information: false,
      systemDetails: "",
      userNameRules: UserNameRules,
      emailRules: emailRules,
      designationRules: DesignationRules,
      timeForRealTimeMonitoring: null,
      date: new Date().toISOString().substr(0, 10),
      nameError: false,
      save: "",
      pickerShow: false,
      disabled_super: false,
      disabled: false,
      dashboard: true,
      task: true,
      team_member: true,
      project: true,
      reports: true,
      work_diary: true,
      shifts: ["Morning", "Evening", "Night"],
      selectedShift: "",
      EditName: "",
      EditDept: "",
      EditDesg: "",
      EditKeystrokesDelay: "",
      selected_department_id: null,
      activelist: "",
      getUserSetting: "",
      hash_id: "",
      department_option: [],
      Department: [],
      list_department: [],
      form_error: [],
      check_department: false,
      search_add_department: null,
      select_department: {
        hash_id: null,
        name: null,
      },
    };
  },
  watch: {},
  computed: {
    ...mapState("auth", ["user"]),
    /**
     * Return the selected company
     */
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
    selectedCompanyUserStatus() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the PF company
     */
    checkKey() {
      let key = false;
      if (this.$route.params.id) {
        let param_id = sha256(this.$route.params.id);
        let checkCompanyId = this.$store.state.pf.keysData.find(
          (data) => data == param_id
        );
        if (checkCompanyId) {
          key = true;
        }
      }
      return key;
    },
    /**
     * Return the deparments list
     */
    departments() {
      let department = this.list_department;
      if (this.search_add_department) {
        department = this.list_department.filter((department) =>
          department.name
            .toLowerCase()
            .includes(this.search_add_department.toLowerCase())
        );
      }
      return department;
    },
    /**
     * Return the is Admin Status
     */
    adminPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.admin_permission) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  mounted() {
    /**
     * Custom event to update member data
     * and trigger edit modal
     */
    this.$eventBus.$on("editUserInfo", (member) => {
      this.member = member;
      this.selectedTab = "user";
      this.EditName = this.member.name;
      this.selected_department_id = null;
      this.EditDept = this.member.department_name;
      this.EditDesg = this.member.designation;
      this.adminStatus = false;
      this.showModal(member);
    });
  },

  methods: {
    addDeparmentNew() {
      let data = {
        department_name: this.search,
        member_id: this.member.id,
        company_id: this.$route.params.id,
        department_id: null,
      };
      this.departmentLoader = true;
      this.$store
        .dispatch("custom/search_add_department", data)
        .then((response) => {
          this.search_add_department = null;
          var new_department = {};
          new_department.hash_id = response.data.department.hash_id;
          this.$store.state.custom.departments.push(response.data.department);
          this.$store.state.custom.company_department_list.push(
            response.data.department
          );
          new_department.name = response.data.department.name;
          this.list_department.unshift(new_department);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Department add is successfully",
            snackbarColor: "green",
          });
          this.departmentLoader = false;
        })
        .catch((error) => {
          this.departmentLoader = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for dispalying
     * the list of departments
     */
    show_department() {
      if (this.check_department === true) {
        this.check_department = false;
      } else {
        this.check_department = true;
      }
    },
    select_department_list(department) {
      this.Department = [
        {
          name: department.name,
          value: department.hash_id,
        },
      ];
      this.EditDept = department.name;
      this.selected_department_id = department.hash_id;
      this.check_department = false;
    },
    /**
     * This function is resposible for adding
     * new department in list of departments
     */
    add_department_new() {
      this.search_add_department = this.search_add_department.replace(
        /^\s+/g,
        ""
      );
      if (!this.search_add_department)
        return this.$root.$emit("snack_bar", {
          show: true,
          message: "Department name is required",
          snackbarColor: "red",
        });

      let checkDepartment = this.list_department.find(
        (department) =>
          department.name.toLowerCase() ==
          this.search_add_department.toLowerCase()
      );
      if (checkDepartment) {
        return this.$root.$emit("snack_bar", {
          show: true,
          message: "Department is already exists",
          snackbarColor: "red",
        });
      }
      let data = {
        department_name: this.search_add_department,
        member_id: this.member.id,
        company_id: this.$route.params.id,
        department_id: null,
      };
      this.$store
        .dispatch("custom/search_add_department", data)
        .then((response) => {
          this.search_add_department = null;
          var new_department = {};
          new_department.hash_id = response.data.department.hash_id;
          this.$store.state.custom.departments.push(response.data.department);
          this.$store.state.custom.company_department_list.push(
            response.data.department
          );
          new_department.name = response.data.department.name;
          this.list_department.unshift(new_department);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Department add is successfully",
            snackbarColor: "green",
          });
        })
        .catch((error) => {
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for displaying
     * modal with member data present on it
     */
    showModal(member) {
      this.show_user_account_information = true;
      this.show_user_system_information = false;
      this.selectedShift = "";
      this.$store.commit("custom/toggle_loader", true);
      this.check_department = false;
      let data = {
        member_id: this.member.id,
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/edit_single_user_profile", data)
        .then((response) => {
          if (response.data.user_profile_setting.system_details) {
            this.systemDetails =
              response.data.user_profile_setting.system_details;
          }
          this.$store.commit("custom/toggle_loader", false);
          this.getUserSetting = response.data.user_profile_setting;
          this.EditKeystrokesDelay =  this.getUserSetting.mouse_keystrokes_delay;
          this.list_department = _.orderBy(
            response.data.departments,
            ["name"],
            ["asc"]
          );
          response.data.departments;
          if (response.data.error == true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          }
          this.user_info_modal = true;
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.status === 403) {
            setTimeout(() => {
              this.$store.commit("custom/toggle_loader", false);
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }, 500);
          }
        });
    },
    /**
     * This function is resposible for Updating
     * user information by sumitting form data
     */
    submitEditProfile(e) {
      this.form_error = [];
      this.check_department = false;
      if (!this.EditName) this.form_error.push("Name required.");
      if (!this.form_error.length) {
        let data = {
          member_name: this.EditName,
          department_name: this.EditDept,
          secondary_email: this.getUserSetting.secondary_email
            ? this.getUserSetting.secondary_email
            : null,
          department_id: this.selected_department_id,
          designation: this.EditDesg,
          mouse_keystrokes_delay: parseInt(this.EditKeystrokesDelay),
          work_shift: this.selectedShift,
          member_id: this.member.id,
          company_id: this.$route.params.id,
          capture_screen: this.getUserSetting.capture_screen,
          blur_screenshots: this.getUserSetting.blur_screenshots,
          manual_time_allowed: this.getUserSetting.manual_time_allowed,
          view_backoffice: this.getUserSetting.view_backoffice,
          edit_task: this.getUserSetting.edit_task,
          dashboard: this.getUserSetting.dashboard,
          project: this.getUserSetting.project,
          team_member: this.getUserSetting.team_member,
          task: this.getUserSetting.task,
          work_diary: this.getUserSetting.work_diary,
          reports: this.getUserSetting.reports,
          billing: this.getUserSetting.billing,
          admin_permission: this.getUserSetting.admin_permission,
        };
        this.$store.commit("custom/set_loader", true);
        this.$store
          .dispatch("custom/edit_customer_profile", data)
          .then((response) => {
            this.refreshCompany(this.member.id);
            this.$store.commit("custom/set_loader", false);
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.user_info_modal = false;
            if (response.data.error == true) {
              return this.$root.$emit("snack_bar", {
                show: true,
                message: response.data.error_msg,
                snackbarColor: "red",
              });
            }
            (this.member.name = this.EditName),
              (this.member.department_name = this.EditDept),
              (this.member.designation = this.EditDesg),
              this.$root.$emit("snack_bar", {
                show: true,
                message: response.data.error_msg,
                snackbarColor: "green",
              });
          })
          .catch((error) => {
            this.$store.commit("custom/set_loader", false);
            if (error.response.data.error_msg) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }
            if (
              error.response.data.errors &&
              error.response.data.errors.member_name
            ) {
              this.$root.$emit("snack_bar", {
                show: true,
                message:
                  error.response.data.errors &&
                  error.response.data.errors.member_name[0],
                snackbarColor: "red",
              });
            }
            if (
              error.response.data.errors &&
              error.response.data.errors.designation
            ) {
              this.$root.$emit("snack_bar", {
                show: true,
                message:
                  error.response.data.errors &&
                  error.response.data.errors.designation[0],
                snackbarColor: "red",
              });
            }
            if (error.response.status === 403) {
              setTimeout(() => {
                this.$root.$emit("snack_bar", {
                  show: true,
                  message: error.response.data.error_msg,
                  snackbarColor: "red",
                });
              }, 500);
            }
          });
      }
    },
    /**
     * This function is resposible for
     * Updating inteval time of screenshots
     */
    pluralize: (count, noun, suffix = "s") =>
      `${count} ${noun}${count !== 1 ? suffix : ""}`,
    update_member_interval(interval, $event) {
      let member = this.member;
      if (member.member_status === "archive" || interval < 1 || interval > 10)
        return;
      let company_id = this.$route.params.id;
      let data = {
        member_id: member.id,
        interval,
        company_id,
      };
      this.$store
        .dispatch("custom/update_member_interval", data)
        .then((response) => {
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.getUserSetting.interval =
              response.data.user_profile_setting.interval;
          }
        })
        .catch(() => {});
    },
    /**
     * This function is resposible for
     * assign admin permssion or supersecure user
     */
    make_admin(role_type, $event) {
      if (role_type == "ADMIN") {
        this.disabled = true;
      } else if (role_type == "SUPERSECUREUSER") {
        (this.is_supersecure = true), (this.disabled_super = true);
      }
      let member = this.member;
      if (member.member_status === "archive") {
        return;
      }
      let data = {
        member_id: member.id,
        company_id: this.$route.params.id,
        role_type: role_type,
        dashboard: true,
        task: true,
        project: true,
        reports: true,
        team_member: true,
        work_diary: true,
        billing: true,
        admin_permission: true,
      };
      this.updatingRole = true;
      this.$store
        .dispatch("custom/make_remove_admin", data)
        .then((response) => {
          if (response.data.error === false) {
            this.refreshCompany(member.id);
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            if (role_type == "ADMIN") {
              this.disabled = false;
            } else if (role_type == "SUPERSECUREUSER") {
              this.disabled_super = false;
            }
            if (role_type == "ADMIN") {
              this.$set(this.getUserSetting, "project", true);
              this.$set(this.getUserSetting, "dashboard", true);
              this.$set(this.getUserSetting, "reports", true);
              this.$set(this.getUserSetting, "team_member", true);
              this.$set(this.getUserSetting, "work_diary", true);
              this.$set(this.getUserSetting, "task", true);
              this.$set(this.getUserSetting, "billing", true);
              this.$set(this.getUserSetting, "admin_permission", true);
              this.member.admin = role_type;
            }
            if (role_type == "SUPERSECUREUSER") {
              this.member.super_secure_user = "SUPERSECUREUSER";
            }
            this.updatingRole = false;
          }
        })
        .catch((error) => {
          this.updatingRole = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });
          if (role_type == "ADMIN") {
            this.disabled = false;
          } else if (role_type == "SUPERSECUREUSER") {
            this.disabled_super = false;
          }
        });
    },
    refreshCompany(member) {
      let emit_key = this.$route.params.id + "_" + member;
      this.$socket.emit("user_role_update", emit_key);
    },
    /**
     * This function is resposible for
     * remove admin permssion or supersecure user
     */
    remove_member_role(role_type, $event) {
      this.is_admin = true;
      if (role_type == "ADMIN") {
        this.disabled = true;
      } else if (role_type == "SUPERSECUREUSER") {
        this.disabled_super = true;
      }
      let member = this.member;
      if (member.member_status === "archive") {
        return;
      }
      this.adminStatus = false;
      let data = {
        member_id: member.id,
        company_id: this.$route.params.id,
        role_type: role_type,
      };
      this.updatingRole = true;
      this.$store
        .dispatch("custom/remove_member_role", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.refreshCompany(member.id);
          if (response.data.error === false) {
            if (role_type == "ADMIN") {
              this.disabled = false;
            } else if (role_type == "SUPERSECUREUSER") {
              this.disabled_super = false;
            }
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            if (role_type == "ADMIN") {
              this.member.admin = null;
            }
            if (role_type == "SUPERSECUREUSER") {
              this.member.super_secure_user = null;
            }
            this.updatingRole = false;
          }
        })
        .catch(() => {
          this.updatingRole = false;
          if (role_type == "ADMIN") {
            this.disabled = false;
          } else if (role_type == "SUPERSECUREUSER") {
            this.disabled_super = false;
          }
        });
    },
  },
};
</script>


<style>
.my-checkbox .v-label {
  font-size: 12px;
  color: rgb(54, 69, 79);
}
</style>

<style scoped>
.intervalTime {
  text-align: center;
  color: #2758f6;
}

.custom-department {
  width: 100%;
}
.edit-margin {
  height: 42px !important;
  border-radius: 3px !important;
}

.img.image-icon {
  position: absolute;
  margin-top: 8px;
  cursor: pointer;
}
.department {
  height: 200px;
  width: 359px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 1px 3px 12px 0 rgba(151, 147, 147, 0.34);
  padding-top: 16px;
  margin-top: 3px;
  position: absolute;
  z-index: 1;
}
li.list-department {
  padding: 6px;
  cursor: pointer;
  color: #36454f;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  padding-left: 16px;
}
span.label-department {
  height: 20px;
  width: 83px;
  color: #36454f;
  font-family: "Poppins Medium";
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 20px;
  padding-left: 16px;
}
.department-input {
  box-sizing: border-box;
  height: 27px;
  width: 304px;
  border: 1px solid #cbdaf2;
  border-radius: 2px;
  margin-left: 14px;
  padding: 7px;
}

.scroll-department {
  height: 124px;
  overflow-y: scroll;
}
img.image-dep {
  position: absolute;
  top: 239px;
  right: 27px;
}
.add_select_dep {
  cursor: pointer;
}
.department_dev {
  padding-top: 8px;
}
li.list-department:hover {
  color: #2758f6 !important;
  background-color: #e2e6ec !important;
}

.active_department {
  color: #2758f6 !important;
  background-color: #e2e6ec !important;
}
#department_add::placeholder {
  color: #36454f;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
/* last line */
</style>
